import React from "react";
import ArrowLeft from "@/assets/svg/arrow_left_grey.svg";
import ArrowRight from "@/assets/svg/arrow_right.svg";

interface Props {
  gotoPrev: () => void;
  gotoNext: () => void;
}

export const ArrowControls = ({ gotoNext, gotoPrev }: Props) => (
  <div className="flex justify-center xl:justify-start">
    <button
      type="button"
      name="previous"
      aria-labelledby="previous"
      className="w-8 h-8"
      onClick={gotoPrev}
    >
      <span hidden id="previous">
        Previous
      </span>
      <img src={ArrowLeft} alt="" aria-hidden="true" />
    </button>

    <button
      type="button"
      name="next"
      aria-labelledby="next"
      className="w-8 h-8"
      onClick={gotoNext}
    >
      <span hidden id="Next">
        Next
      </span>
      <img src={ArrowRight} alt="" aria-hidden="true" />
    </button>
  </div>
);
