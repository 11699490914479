import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import sortBy from "lodash/sortBy";
import { useMemberModal } from "@/hooks/useMemberModal";
import { ImageComponent } from "@/components/elements/Image";
import * as styles from "./styles.module.css";
import MemberModal from "./MemberModal";

interface Props extends OurTeamSectionType {
  lang: Language;
}

function OurTeamSection({ heading, subheading, lang = "en" }: Props) {
  const teamData = useStaticQuery(graphql`
    query TeamMembersQuery {
      members: allStoryblokEntry(
        filter: { field_component: { eq: "data_teamMember" } }
      ) {
        edges {
          node {
            content
            uuid
          }
        }
      }
    }
  `);

  const parsedMembers = useMemo(() => {
    const members: MemberType[] =
      teamData && teamData.members
        ? teamData.members.edges.map(({ node }: SbContentNode) => ({
            ...JSON.parse(node.content),
            uuid: node.uuid,
          }))
        : [];

    const sortedMembers = sortBy(members, (el) =>
      el.order ? parseInt(el.order, 10) : null
    );
    return {
      members: sortedMembers,
    };
  }, [teamData]);

  const { open, member, handleOpenModal, handleCloseModal } = useMemberModal(
    parsedMembers.members
  );

  return (
    <section className="py-12 xl:py-32" id="team">
      <div className="px-7">
        <div className="text-center mb-7 lg:mb-20">
          <h2 className="text-4xl font-bold lg:text-h2-hero-mobile">
            {heading}
          </h2>
          <p className="text-base font-fieldwork-hum lg:max-w-3xl lg:mx-auto lg:text-lg lg:leading-normal">
            {subheading}
          </p>
        </div>
        <div className="flex flex-wrap gap-4 md:gap-6 max-w-x-big lg:mx-auto lg:gap-8 xl:px-4 2xl:gap-12">
          {parsedMembers.members.map((member) => (
            <button
              type="button"
              key={member.uuid}
              id={member.uuid}
              className={styles.card}
              onClick={handleOpenModal}
            >
              <ImageComponent
                className={styles.cardMemberImg}
                data={member.photo}
                alt={member.name}
                options={{ quality: 50, width: "100%", height: "100%" }}
                fixed
              />
              <div
                className={`${styles.cardMemberInfo} px-2 pt-4 pb-3 lg:pl-7 lg:py-7 bg-white absolute left-0 bottom-0 w-full`}
              >
                <p className="leading-tight lg:text-xl text-left">
                  {member.name}
                </p>
                <p className="text-tiny text-secondary font-semibold lg:text-base text-left">
                  {member.position}
                </p>
              </div>
            </button>
          ))}
        </div>
        {member && (
          <MemberModal
            member={member}
            open={open}
            closeHandler={handleCloseModal}
            onClose={handleCloseModal}
            center
            showCloseIcon={false}
            blockScroll={false}
            lang={lang}
          />
        )}
      </div>
    </section>
  );
}

export default OurTeamSection;
