import React from "react";
import { graphql, PageProps } from "gatsby";

import { useStoryblok } from "@/base/lib/storyblok";
import StoryblokComponent from "@/base/helpers/connectSbComponent";
import Layout from "../layouts/Layout";

interface PageData {
  layout: StoryblokStory;
}

export default function Page({
  pageContext,
  location,
  data,
}: PageProps<PageData, PageContext<CommonPageType>>) {
  const { lang, languages, story } = pageContext;
  const { layout } = data;
  const pageStory = useStoryblok<CommonPageType>(story, location);
  const { content } = pageStory;
  const { metadata } = content;
  const parsedLayoutContent = JSON.parse(layout.content) as CommonLayoutType;

  const { header, footer } = parsedLayoutContent || {};

  if (story == null) {
    return (
      <div>
        <h3>Editor page</h3>
      </div>
    );
  }

  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      seo={{ ...metadata, og_type: "website" }}
      siteName={story.name}
      pathname={location.pathname}
      hash={location.hash}
      languages={languages}
      lang={lang}
    >
      <StoryblokComponent blok={content as CommonPageType} lang={lang} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query PageQuery {
    layout: storyblokEntry(field_component: { eq: "common_layout" }) {
      content
    }
  }
`;
