import React from "react";
import Slider from "react-slick";

const SLIDER_SETTINGS = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 567,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

export default function CompaniesSliderSection({
  slides,
}: CompaniesSliderSectionType) {
  return (
    <section className="relative px-7 pt-7 pb-2 lg:pb-9 bg-light-grey lg:bg-white xl:px-44 lg:mt-8">
      <Slider {...SLIDER_SETTINGS}>
        {slides.map((slide) => (
          <div key={slide.id}>
            <div className="w-full h-10 mb-5 flex justify-center items-center lg:mb-0">
              <img
                className="max-w-80 max-h-10"
                src={slide.filename}
                alt={slide.alt}
              />
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
}
