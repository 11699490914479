import React from "react";
import Slider from "react-slick";
import { ImageComponent } from "@/components/elements/Image";
import { CustomBullet } from "./CustomBullet";
import * as styles from "./styles.module.css";

interface Props {
  slides: SbSlideType[];
}

const SLIDER_SETTINGS = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  customPaging: CustomBullet,
  dotsClass: styles.sliderDots,
};

export const SlickSlider = ({ slides }: Props) => (
  <Slider {...SLIDER_SETTINGS}>
    {slides.map(
      (slide) =>
        slide.image && (
          <ImageComponent
            key={slide._uid}
            fluid
            data={slide.image}
            style={{ borderRadius: "0.8rem" }}
          />
        )
    )}
  </Slider>
);
