import React from "react";
import { useMatchMediaContext } from "@/context";
import LinkComponent from "@/components/elements/Link";

interface Props {
  companies: CompanyType[];
}

export const CompaniesList = ({ companies }: Props) => {
  const { isTablet } = useMatchMediaContext();
  return (
    <>
      {!isTablet && <hr className="my-8" />}
      <span className="font-semibold mr-4">Companies:</span>
      <ul className="flex flex-wrap">
        {companies.map(({ uuid, name, content }) => {
          const projectUrl = content.project_socials.filter((social) => {
            return social.type === "website";
          });
          return (
            <li
              className="text-secondary capitalize underline mr-5 last:mr-0"
              key={uuid}
            >
              {projectUrl && projectUrl.length ? (
                <LinkComponent url={projectUrl[0].url} type="internal">
                  {name}
                </LinkComponent>
              ) : (
                <p>{name}</p>
              )}
            </li>
          );
        })}
      </ul>
      {!isTablet && <hr className="mt-8" />}
    </>
  );
};
