import React, { useCallback, useEffect, useMemo, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import sortBy from "lodash/sortBy";
import clsx from "clsx";

import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { ReactComponent as ArrowLeft } from "@/assets/svg/vertex-arrow-left.svg";
import { useMatchMediaContext } from "@/context";

import { Search } from "./Search";
import { PortfolioCategories } from "./PortfolioCategories";
import { PortfolioList } from "./PortfolioList";
import { CategoriesList } from "./types";

export default function PortfolioSection({
  title,
  active_category,
  search_placeholder,
}: PortfolioSectionType) {
  const { isTablet } = useMatchMediaContext();
  const activeCategory = active_category || "All";

  const lists = useStaticQuery(graphql`
    query PortfolioQuery {
      projects: allStoryblokEntry(
        filter: { field_component: { eq: "data_project" } }
      ) {
        edges {
          node {
            content
            uuid
          }
        }
      }
      categories: allStoryblokDatasourceEntry(
        filter: { data_source: { eq: "project-categories" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            value
          }
        }
      }
    }
  `);

  const initialState = useMemo(() => {
    const projects: PortfolioProjectType[] =
      lists && lists.projects
        ? lists.projects.edges.map(({ node }: SbContentNode) => ({
            ...(JSON.parse(node.content) as PortfolioProjectType),
            uuid: node.uuid,
          }))
        : [];
    const sortedProjects: PortfolioProjectType[] = sortBy(
      projects,
      (el: PortfolioProjectType) =>
        el.project_weight ? parseInt(el.project_weight, 10) : null
    );

    const categories =
      lists && lists.categories
        ? lists.categories.edges.map(({ node }: SbContentNode) => node)
        : [];
    return {
      projects: sortedProjects,
      categories: Object.assign(
        {
          all: {
            name: "All",
            amount: projects.length,
          },
        },
        ...categories.map((category: PortfolioCategoryType) => ({
          [category.value]: {
            name: category.name,
            amount: projects.filter(
              (item) =>
                !!item.project_category &&
                item.project_category.indexOf(category.value) >= 0
            ).length,
          },
        }))
      ) as CategoriesList,
    };
  }, [lists]);

  const [filter, setFilter] = useState(activeCategory);
  const [filteredProjects, setFilteredProjects] = useState(
    initialState.projects
  );
  const [categoriesMenuVisible, setCategoriesMenuVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, handleIsSearching] = useState(false);

  function changeFilter(newFilter: string) {
    if (filter === newFilter && !isSearching) {
      return;
    }
    const newFilteredProjects = initialState.projects.filter((project) =>
      newFilter === "all"
        ? true
        : !!project.project_category &&
          project.project_category.indexOf(newFilter) >= 0
    );

    setFilteredProjects(newFilteredProjects);
    handleIsSearching(false);
    setSearchValue("");
    setFilter(newFilter);
  }

  function removeFilter() {
    setFilter("all");
    setFilteredProjects(initialState.projects);
  }

  function handleOnChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
    if (isSearching) {
      handleIsSearching(false);
    }
  }

  const handleOnSearch = useCallback(() => {
    if (searchValue.length < 3) {
      return;
    }
    const parsedSearchValue = searchValue.toLowerCase();
    const newFilteredProjects = initialState.projects.filter((project) => {
      if (project.project_name.toLowerCase().indexOf(parsedSearchValue) >= 0) {
        return true;
      } else if (
        project.project_founders?.some((ceo) =>
          ceo.title
            ? ceo.title.toLowerCase().indexOf(parsedSearchValue) >= 0
            : false
        )
      ) {
        return true;
      } else if (
        project.project_partner?.some(
          (partner) =>
            partner.name.toLowerCase().indexOf(parsedSearchValue) >= 0
        )
      ) {
        return true;
      }
      return false;
    });
    setFilteredProjects(newFilteredProjects);
    handleIsSearching(true);
    setFilter("all");
  }, [searchValue, initialState.projects]);

  function handleOnRemoveSearch() {
    handleIsSearching(false);
    setFilteredProjects(initialState.projects);
    setSearchValue("");
  }

  const enterButtonSearch = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleOnSearch();
      }
    },
    [handleOnSearch]
  );

  useEffect(() => {
    if (searchValue) {
      const node = document.querySelector("#search");

      node?.addEventListener("keydown", enterButtonSearch as EventListener);

      return () =>
        node?.removeEventListener(
          "keydown",
          enterButtonSearch as EventListener
        );
    }
  }, [searchValue, enterButtonSearch]);

  useEffect(() => {
    if (activeCategory !== "All") {
      const newFilteredProjects = initialState.projects.filter(
        (project) =>
          !!project.project_category &&
          project.project_category.indexOf(activeCategory) >= 0
      );
      setFilteredProjects(newFilteredProjects);
    }
  }, []);

  return (
    <section
      id="portfolio"
      className="pt-14 pb-9 lg:pb-40 px-7 bg-grey lg:px-10 xl:px-8 2xl:px-0"
    >
      <div className="xl:max-w-x-big xl:m-auto xl:px-4">
        <div className="flex items-center justify-center xl:justify-start xl:ml-80">
          <h2 className="mb-0 text-7xl lg:text-8xl lg:order-2">{title}</h2>
          {!isTablet ? (
            <button
              className="flex flex-col items-center ml-8 pointer"
              onClick={() => setCategoriesMenuVisible(!categoriesMenuVisible)}
            >
              <span
                style={{ height: 3, width: 18 }}
                className="block bg-black"
              ></span>
              <span
                style={{ height: 3, width: 12 }}
                className="block bg-black my-0.5"
              ></span>
              <span
                style={{ height: 3, width: 6 }}
                className="block bg-black"
              ></span>
            </button>
          ) : (
            <ArrowLeft className="order-1 transform translate-y-5 lg:mr-10 " />
          )}
        </div>

        <PortfolioCategories
          categories={initialState.categories}
          activeFilter={filter}
          onChange={changeFilter}
          isVisible={categoriesMenuVisible}
          closeMenu={() => setCategoriesMenuVisible(false)}
          searchComponent={
            <Search
              placeholder={search_placeholder}
              value={searchValue}
              isSearching={isSearching}
              onChange={handleOnChangeSearch}
              onSearch={handleOnSearch}
              onRemoveSearch={handleOnRemoveSearch}
            />
          }
        />

        <div className="flex items-center justify-center my-4 lg:mb-20">
          <button
            className={clsx(
              "py-1 px-3 flex items-center border-2 border-secondary rounded-2xl text-secondary",
              {
                hidden: filter === "all",
              }
            )}
            onClick={removeFilter}
          >
            <span className="leading-none">{filter}</span>
            <CloseIcon className="block ml-4 fill-current w-3 h-3" />
          </button>
        </div>

        <PortfolioList projects={filteredProjects} />
      </div>
    </section>
  );
}
