import React from "react";
import MarkdownComponent from "@/components/elements/Markdown";

import * as styles from "./styles.module.css";

function HeadingContent({ content, heading }: HeadingContentType) {
  return (
    <section className="py-10 px-4 lg:py-20">
      <div className="max-w-x-big mx-auto">
        {heading && (
          <h1 className="text-7xl leading-10 lg:text-8xl lg:leading-normal">
            {heading}
          </h1>
        )}
        {content && (
          <MarkdownComponent className={styles.content_wrapper}>
            {content}
          </MarkdownComponent>
        )}
      </div>
    </section>
  );
}

export default HeadingContent;
