import React, { useState, useEffect } from "react";
import clsx from "clsx";
import LinkComponent from "@/components/elements/Link";
import { useFeaturedPost } from "@/hooks/useLatestNews";
import * as styles from "./styles.module.css";

const DEFAULT_LANG = process.env.GATSBY_DEFAULT_LANG || "en";

interface Props {
  lang?: string;
  postId: string;
}

export function SideWindow({ lang = "en", postId }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const latestPost = useFeaturedPost(postId);

  const articleUrl =
    lang === DEFAULT_LANG
      ? `/news/${latestPost?.slug}`
      : `/${lang}/news/${latestPost?.slug}`;

  const toggleWindow = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (latestPost?.name) {
      setIsOpen(true);
    }
  }, [latestPost]);

  return (
    <button
      className={clsx(
        "text-white text-left py-5 pl-16 pr-4 xl:max-w-md lg:max-w-xs absolute lg:top-16 bg-black bg-opacity-50 rounded-r-xl transition-all duration-300 ease-in-out",
        styles.container,
        {
          [styles.closed]: !isOpen,
          "translate-x-0": isOpen,
        }
      )}
      onClick={toggleWindow}
    >
      <div className="relative pr-5">
        {isOpen && (
          <button type="button" onClick={toggleWindow} aria-labelledby="close">
            <span hidden id="close">
              Close
            </span>
            <span
              className="block absolute top-0 right-0 h-px w-3 bg-white border border-white rotate-45 transition-all duration-300 ease-in-out"
              aria-hidden="true"
            ></span>
            <span
              className="block absolute top-0 right-0 h-px w-3 bg-white border border-white -rotate-45 transition-all duration-300 ease-in-out"
              aria-hidden="true"
            ></span>
          </button>
        )}
        <p className="text-sm mb-6">Latest News</p>
        <p className="xl:text-3xl lg:text-base italic font-bookman-old leading-normal mb-7">
          {latestPost?.name}
        </p>
        <div
          className={clsx(
            "text-secondary text-sm font-semibold",
            styles.wrapper
          )}
        >
          {latestPost?.full_slug && (
            <LinkComponent url={articleUrl} type="internal">
              Read more
              <div className={styles.arrow}></div>
            </LinkComponent>
          )}
        </div>
      </div>
    </button>
  );
}
