import React, { useRef, useState } from "react";

import Slider from "react-slick";
import { MainSlider } from "./Slider/MainSlider";
import { LogoSlider } from "./Slider/LogoSlider";

export function TestimonialsSection({ list = [] }: TestimonialsSectionType) {
  const [activeSlide, changeActiveSlide] = useState(0);

  const mainSlider = useRef<Slider>(null);
  const logoSlider = useRef<Slider>(null);

  const gotoNext = () =>
    mainSlider.current ? mainSlider?.current.slickNext() : null;
  const gotoPrev = () =>
    mainSlider.current ? mainSlider?.current.slickPrev() : null;

  return (
    <section>
      <div className="relative lg:pt-16">
        <MainSlider
          changeActiveSlide={(slideNr: number) => changeActiveSlide(slideNr)}
          list={list}
          mainSliderRef={mainSlider}
          logoSliderRef={logoSlider}
          gotoNext={gotoNext}
          gotoPrev={gotoPrev}
        />
        <LogoSlider
          activeSlide={activeSlide}
          list={list}
          mainSliderRef={mainSlider}
          logoSliderRef={logoSlider}
          gotoNext={gotoNext}
          gotoPrev={gotoPrev}
        />
      </div>
    </section>
  );
}

export default TestimonialsSection;
