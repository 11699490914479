import React from "react";

import clsx from "clsx";
import { ReactComponent as WhiteLogo } from "@/assets/svg/logo-vertex-white.svg";
import formatDate from "@/utils/formatDate";
import { ImageComponent } from "@/components/elements/Image";
import LinkComponent from "@/components/elements/Link";
import MarkdownComponent from "@/components/elements/Markdown";
import * as styles from "./styles.module.css";

interface Props {
  postData: Pick<PostType, "full_slug"> & CommonPostType;
}

const DEFAULT_LANG = process.env.GATSBY_DEFAULT_LANG || "en";

export const NewsEntry = ({ postData }: Props) => {
  const url =
    postData.full_slug.indexOf(DEFAULT_LANG) >= 0
      ? postData.full_slug.replace(`${DEFAULT_LANG}/`, "")
      : postData.full_slug;
  const linkUrl = postData.external_post
    ? postData.external_link
    : url.replace("news/", "").replace("perspectives/", "");
  const linkType = postData.external_post ? "external" : "internal";

  const { post_author, post_excerpt, post_title, post_hero, post_date } =
    postData;
  const isAuthor = !!post_author && typeof post_author === "object";
  const bgColor = isAuthor
    ? post_author.content?.project_color?.color
    : "#7dcaef";
  const logo = isAuthor ? post_author.content?.project_logoWhite : null;
  const projectName = post_author?.name;

  return (
    <article className="bg-white rounded-lg shadow-lg mb-10 2xl:mb-16">
      <div
        style={{ backgroundColor: bgColor }}
        className="flex justify-end items-center pr-3 rounded-t-lg h-9"
      >
        {isAuthor && logo?.filename ? (
          <img
            src={logo.filename}
            alt={projectName ? projectName : "logo"}
            width={70}
            className="block object-contain"
          />
        ) : (
          <WhiteLogo className="w-5 h-5" />
        )}
      </div>
      <div>{post_hero && <ImageComponent fluid data={post_hero} />}</div>
      <div className="p-10 pb-14 2xl:p-11 2xl:pb-16">
        <span className="text-base opacity-60 mb-6 block font-fieldwork-hum">
          {formatDate(post_date)}
        </span>
        <div className={styles.excerpt}>
          <LinkComponent url={linkUrl} type={linkType}>
            <h2>{post_title}</h2>
          </LinkComponent>
          {post_excerpt && (
            <MarkdownComponent>{post_excerpt}</MarkdownComponent>
          )}
        </div>
        <div
          className={clsx("flex items-center text-secondary", styles.read_more)}
        >
          <LinkComponent
            url={linkUrl}
            type={linkType}
            className="block leading-none font-fieldwork-hum font-semibold text-xs capitalize lg:text-lg"
          >
            Read more
          </LinkComponent>
          <div className={styles.arrow}></div>
        </div>
      </div>
    </article>
  );
};
