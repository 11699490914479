import Slider from "react-slick";
import React, { RefObject } from "react";
import { ImageComponent } from "@/components/elements/Image";
import { useMatchMediaContext } from "@/context";
import { ArrowControls } from "../ArrowControls";
import * as styles from "./styles.module.css";

interface Props {
  list: TestimonialType[];
  changeActiveSlide: (slideNumber: number) => void;
  logoSliderRef: RefObject<Slider>;
  mainSliderRef: RefObject<Slider>;
  gotoNext: () => void;
  gotoPrev: () => void;
}

export const MainSlider = ({
  list,
  changeActiveSlide,
  logoSliderRef,
  mainSliderRef,
  gotoNext,
  gotoPrev,
}: Props) => {
  const { isDesktop } = useMatchMediaContext();
  return (
    <>
      <Slider
        asNavFor={logoSliderRef.current ?? undefined}
        ref={mainSliderRef}
        arrows={false}
        dots={false}
        afterChange={changeActiveSlide}
        fade={true}
        autoplay={true}
        autoplaySpeed={5000}
      >
        {list.map((item) => {
          const imageVericalPos = item.image_position || "center";
          return (
            <div key={item._uid}>
              <div className={styles.bigImageWrapper}>
                {item.company_image && (
                  <ImageComponent
                    fluid
                    data={item.company_image}
                    style={{ height: "100%" }}
                    objectFit="cover"
                    objectPosition={`50% ${imageVericalPos}`}
                  />
                )}
              </div>
              <div className={styles.testimonial}>
                <p className="text-2xl h-full font-bookman-old font-medium italic leading-tight mb-7 xl:mb-0 xl:text-3xl xl:leading-normal">
                  <strong className="mr-2">{item.name}</strong>
                  {item.content}
                </p>
                {isDesktop ? null : (
                  <ArrowControls gotoNext={gotoNext} gotoPrev={gotoPrev} />
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};
