import React from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import { ReactComponent as WhiteLogo } from "@/assets/svg/logo-vertex-white.svg";
import LinkComponent from "@/components/elements/Link";
import { truncateText } from "@/utils/truncateText";
import formatDate from "@/utils/formatDate";

import { CustomPostType } from "../types";
import * as styles from "./styles.module.css";

const DEFAULT_LANG = process.env.GATSBY_DEFAULT_LANG || "en";

interface Props {
  data: CustomPostType;
  lang: string;
}

export default function NewsItem({ lang, data }: Props) {
  const isAuthor = !!data.post_author && typeof data.post_author === "object";
  const headerColor = isAuthor
    ? data.post_author.content?.project_color?.color
    : "";
  const headerLogo = isAuthor
    ? data.post_author.content?.project_logoWhite?.filename
    : "";
  const url =
    lang === DEFAULT_LANG ? data.full_slug.replace(`en/`, "") : data.full_slug;
  const postUrl = data.external_post ? data.external_link : url;
  const linkType = data.external_post ? "external" : "internal";

  return (
    <article className={styles.wrapper}>
      <div
        style={{ backgroundColor: isAuthor ? headerColor : "#7dcaef" }}
        className={clsx(
          "h-12 flex items-center justify-end rounded-t-lg px-8",
          {
            "bg-primary":
              isEmpty(data.post_author) || typeof data.post_author === "string",
          }
        )}
      >
        {isAuthor ? (
          <img className="w-20" src={headerLogo} alt="author-logo" />
        ) : (
          <WhiteLogo className="w-5 h-5" />
        )}
      </div>
      <div className="py-5 px-7">
        <time className="text-tiny text-left text-primary font-fieldwork-hum lg:text-xxs lg:leading-8">
          {formatDate(data.post_date)}
        </time>
        <div className="mt-5 mb-8 h-44 lg:h-64 xl:h-56 2xl:h-52 ">
          <LinkComponent type={linkType} url={postUrl}>
            <h3
              title={data.post_title}
              className="text-left font-bookman-old italic text-primary leading-10 font-normal text-3xl lg:text-6xl"
            >
              {truncateText(data.post_title, 70)}
            </h3>
          </LinkComponent>
        </div>
        <div className="cursor-pointer flex align-middle">
          <span className="text-secondary font-semibold lg:text-lg">
            <LinkComponent type={linkType} url={postUrl}>
              Read More
            </LinkComponent>
          </span>
          <div className="ml-4">
            <div className={styles.arrow}></div>
          </div>
        </div>
      </div>
    </article>
  );
}
