import React from "react";
import SbEditable from "storyblok-react";

import StoryblokComponent from "@/base/helpers/connectSbComponent";

interface Props extends CommonPageType {
  lang: Language;
}

export default function PageComponent({ body, lang }: Props) {
  return (
    <div>
      {body &&
        body.map((item) => {
          return (
            //@ts-ignore
            <SbEditable key={item._uid} content={item}>
              <StoryblokComponent blok={item} lang={lang} />
            </SbEditable>
          );
        })}
    </div>
  );
}
