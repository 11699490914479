import React from "react";

import Page from "@/components/layouts/Page";
import OurStorySection from "@/components/sections/OurStorySection";
import HeroSection from "@/components/sections/HeroSection";
import TestimonialsSection from "@/components/sections/TestimonialsSection";
import OurTeamSection from "@/components/sections/OurTeamSection";
import PortfolioSection from "@/components/sections/PortfolioSection";
import CompaniesSliderSection from "@/components/sections/CompaniesSliderSection";
import NumbersSection from "@/components/sections/NumbersSection";
import CtaSection from "@/components/sections/SectionCta";
import LatestNewsSection from "@/components/sections/LatestNewsSection";
import NewsMain from "@/components/sections/NewsMain";
import HeadingContentSection from "@/components/sections/HeadingContentSection";

const ComponentNotFound = ({ component }: { component: string }) => (
  <h3>
    The component <strong style={{ color: "red" }}>{component}</strong> has not
    been created yet.
  </h3>
);

interface Props {
  lang: Language;
  blok:
    | CommonPageType
    | HeroSectionType
    | CompaniesSliderSectionType
    | NumbersSectionType
    | CtaSectionType
    | OurStorySectionType
    | TestimonialsSectionType
    | PortfolioSectionType
    | OurTeamSectionType
    | LatestNewsSectionType
    | NewsMainSectionType
    | HeadingContentType;
}

const StoryblokComponent: React.FunctionComponent<Props> = ({ blok, lang }) => {
  // check if component is defined above
  switch (blok.component) {
    case "common_page":
      return <Page {...blok} lang={lang} />;
    case "section_hero":
      return <HeroSection {...blok} lang={lang} />;
    case "section_companiesSlider":
      return <CompaniesSliderSection {...blok} />;
    case "section_cta":
      return <CtaSection {...blok} />;
    case "section_ourStory":
      return <OurStorySection {...blok} />;
    case "section_numbers":
      return <NumbersSection {...blok} />;
    case "section_portfolio":
      return <PortfolioSection {...blok} />;
    case "section_testimonials":
      return <TestimonialsSection {...blok} />;
    case "section_ourTeam":
      return <OurTeamSection {...blok} lang={lang} />;
    case "section_latestNews":
      return <LatestNewsSection {...blok} lang={lang} />;
    case "section_newsMain":
      return <NewsMain {...blok} lang={lang} />;
    case "section_headingContent":
      return <HeadingContentSection {...blok} />;
    default:
      return <ComponentNotFound {...(blok as ComponentNotFoundType)} />;
  }
};

export default StoryblokComponent;
