import clsx from "clsx";
import React from "react";
import Slider from "react-slick";
import { CustomPostType } from "../types";

interface Props {
  slides: CustomPostType[];
  activeSlide: number;
  sliderRef: React.RefObject<Slider>;
}

export default function SlideControl({
  slides = [],
  sliderRef,
  activeSlide,
}: Props) {
  return (
    <ul className="flex justify-center mt-9">
      {slides?.map((slide, index) => (
        <li key={`slide-${slide.uuid}`} className="ml-3 first:ml-0">
          <button
            type="button"
            aria-label="slider_dot"
            className={clsx(
              "h-2 w-2 bg-black bg-opacity-20 rounded-full cursor-pointer",
              {
                "bg-secondary bg-opacity-100": activeSlide === index,
              }
            )}
            onClick={() => sliderRef?.current?.slickGoTo(index)}
          />
        </li>
      ))}
    </ul>
  );
}
