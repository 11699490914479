import React from "react";
import clsx from "clsx";
import LinkComponent from "@/components/elements/Link";
import MarkdownComponent from "@/components/elements/Markdown";
import * as styles from "./styles.module.css";

export default function CtaSection({
  cta_background,
  cta_link,
  cta_content,
}: CtaSectionType) {
  const ctaLink = cta_link && cta_link[0];
  return (
    <section
      style={{ backgroundImage: `url(${cta_background?.image})` }}
      className="text-center text-white bg-cover"
    >
      <div className="bg-black bg-opacity-50 py-32 px-16">
        <div className={clsx("mb-10", styles.wrapper)}>
          {cta_content && <MarkdownComponent>{cta_content}</MarkdownComponent>}
        </div>

        {ctaLink && (
          <button type="button" aria-label="learn_more">
            <LinkComponent
              {...ctaLink}
              className={styles.learn__more__button}
            />
          </button>
        )}
      </div>
    </section>
  );
}
