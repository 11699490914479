import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

import { useMatchMediaContext } from "@/context";
import LinkComponent from "@/components/elements/Link";

import SlideControl from "./SlideControl";
import NewsItem from "./NewsItem";
import { CustomPostType } from "./types";

const SLIDER_SETTINGS: Settings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

interface Props extends LatestNewsSectionType {
  lang: Language;
}

export default function LatestNewsSection({
  link_showMore,
  title,
  lang = "en",
  category_filter,
}: Props) {
  const [latestNews, setLatestNews] = useState<CustomPostType[]>([]);
  const [activeSlide, changeActiveSlide] = useState(0);
  const { isTablet } = useMatchMediaContext();
  const sliderRef = useRef<Slider>(null);
  const renderControls = useCallback(
    () => (
      <SlideControl
        slides={latestNews}
        sliderRef={sliderRef}
        activeSlide={activeSlide}
      />
    ),
    [activeSlide, latestNews]
  );

  useEffect(() => {
    fetch(`/posts-data/${lang}-${category_filter}-1.json`)
      .then((res) => res.json())
      .then((res: StoryblokStory[] | Record<string, unknown>) => {
        if (Array.isArray(res)) {
          const posts: CustomPostType[] = res.map((el) =>
            Object.assign(JSON.parse(el.content), {
              uuid: el.uuid,
              slug: el.slug,
              full_slug: el.full_slug,
            })
          );
          setLatestNews(posts);
        }
      })
      .catch((err) => console.log("ERROR FROM FETCHING POSTS", err));
  }, []);

  return (
    <section id="latestNews" className="pt-16 pb-28 lg:py-32 bg-light-grey">
      <h2 className="text-center text-3xl font-bold font-fieldwork-hum leading-tight lg:text-7.5xl lg:leading-none">
        {title}
      </h2>
      <div className="my-14 gap-8 max-w-x-big mx-auto px-3 lg:px-4 lg:grid lg:grid-cols-2 lg:mb-20 xl:gap-12 xl:grid-cols-3 2xl:gap-10">
        {isTablet ? (
          latestNews.map((item) => (
            <NewsItem data={item} key={item.uuid} lang={lang} />
          ))
        ) : (
          <>
            <Slider
              {...SLIDER_SETTINGS}
              afterChange={changeActiveSlide}
              ref={sliderRef}
            >
              {latestNews.map((item) => (
                <NewsItem data={item} key={item.uuid} lang={lang} />
              ))}
            </Slider>
            {renderControls()}
          </>
        )}
      </div>
      <div className="w-max mx-auto">
        {link_showMore && (
          <LinkComponent
            className="cursor-pointer hover:opacity-75 rounded-lg bg-secondary lg:py-6 lg:px-14 text-lg text-white capitalize px-12 py-5 font-semibold"
            {...link_showMore[0]}
          />
        )}
      </div>
    </section>
  );
}
