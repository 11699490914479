import React from "react";
import SocialLink from "@/components/elements/SocialLink";

import * as styles from "./styles.module.css";

interface Props {
  list: SocialLinkType[];
  acquiredBy?: string;
}

export default function Socials({ list, acquiredBy }: Props) {
  return (
    <div className="py-6 my-6 border-b border-t border-grey-1 lg:border-0 lg:py-0 lg:my-0">
      <div className="flex items-center justify-center flex-wrap lg:justify-end">
        {list.map((el) => (
          <SocialLink
            key={el._uid}
            _uid={el._uid}
            type={el.type}
            url={el.url}
            component={el.component}
            className={styles.modal_socialIcon}
          />
        ))}
      </div>
      {acquiredBy && (
        <div className="text-md leading-5 text-center mt-7 lg:hidden">
          <p>Acquired by: {acquiredBy}</p>
        </div>
      )}
    </div>
  );
}
