import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { NewsEntry } from "./NewsEntry";

import * as styles from "./styles.module.css";

const DEFAULT_LANG: Language =
  (process.env.GATSBY_DEFAULT_LANG as Language) || "en";
const NEWS_OFFSET = process.env.GATSBY_NEWS_OFFSET
  ? parseInt(process.env.GATSBY_NEWS_OFFSET, 10)
  : 6;

interface Props extends NewsMainSectionType {
  lang: Language;
}

function NewsMain({
  heading,
  loadMore_label,
  category_filter,
  lang = DEFAULT_LANG,
}: Props) {
  const [posts, setPosts] = useState<
    (Pick<PostType, "full_slug"> & CommonPostType)[]
  >([]);
  const [counter, setCounter] = useState(1);
  const [isMorePosts, setIsMorePosts] = useState(true);

  function loadMorePosts() {
    setCounter(counter + 1);
  }

  useEffect(() => {
    fetch(`/posts-data/${lang}-${category_filter}-${counter}.json`)
      .then((res) => res.json())
      .then((res: PostType[] | Record<string, unknown>) => {
        if (Array.isArray(res)) {
          const newPosts = res.map((el) =>
            Object.assign(JSON.parse(el.content), {
              uuid: el.uuid,
              slug: el.slug,
              full_slug: el.full_slug,
            })
          );
          setPosts([...posts, ...newPosts]);
          if (newPosts.length < NEWS_OFFSET) {
            setIsMorePosts(false);
          }
        } else {
          setIsMorePosts(false);
        }
      })
      .catch((err) => console.log("ERROR FROM FETCHING POSTS", err));
  }, [counter]);

  return (
    <section className="pt-8 px-6 bg-gray-100 lg:pt-24">
      <div className="mb-7 lg:mb-0">
        <h1 className="text-5xl font-bold text-center lg:text-9xl">
          {heading}
        </h1>
      </div>

      <Masonry
        breakpointCols={{
          default: 3,
          1200: 2,
          767: 1,
        }}
        className={styles.masonry_wrapper}
      >
        {posts.map((post) => {
          return <NewsEntry postData={post} key={post._uid} />;
        })}
      </Masonry>

      <div
        className={clsx(
          "py-10 lg:py-20 lg:max-w-7lg lg:mx-auto text-center",
          styles.loadButton
        )}
      >
        {isMorePosts && (
          <div className="flex flex-col items-center relative">
            <button
              onClick={loadMorePosts}
              className="font-fieldwork-hum font-semibold opacity-40 capitalize lg:text-base"
            >
              {loadMore_label}
            </button>
            <div className={styles.arrowDown}></div>
          </div>
        )}
      </div>
    </section>
  );
}

export default NewsMain;
